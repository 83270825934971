import React from 'react'

import { SEO } from '@renderbus/common/components'

import Layout from '../molecules/layout'
import { XmasContainer } from './xgt-xmas.atom'
import { XmasContent } from './xgt-xmas.atom'

import XmasBanner from '../molecules/xmas/banner/banner'

import Title1 from '../images/xmas/title-1.png'
import Title2 from '../images/xmas/title-2.png'
import Title3 from '../images/xmas/title-3.png'
import GreenCard1 from '../images/xmas/green-card-1.png'
import GreenCard1M from '../images/xmas/green-card-1-mobile.png'
import GreenCard2 from '../images/xmas/green-card-2.png'
import GreenCard2M from '../images/xmas/green-card-2-mobile.png'

const CardItme = [
  {
    price: 300,
    discount: '送3个月会员',
    worth: '（价值58元）',
    detail: '(内含12张免单券，最高可免1200渲染费)',
  },
  {
    price: 500,
    discount: '送6个月会员',
    worth: '（价值110元）',
    detail: '(内含24张免单券，最高可免2400渲染费)',
  },
  {
    price: 800,
    discount: '送12个月会员',
    worth: '（价值199元） ',
    detail: '(内含48张免单券，最高可免4800渲染费)',
  },
  {
    price: 2000,
    discount: '送12个月会员',
    worth: '+500元渲染券',
    detail: '(内含48张免单券，最高可免4800渲染费)',
  },
]

export const ActivityRules = [
  '1、 用户参与活动完成充值后，赠送的会员直接到账，若30分钟未到账，请咨询效果图客服；',
  '2、 非效果图会员用户完成实名认证/充值后，12小时内自动激活效果图会员，若12小时内未激活，请咨询效果图客服！若用户原已是效果图会员，则赠送的一个月会员时长自动累积到会员剩余时长中；',
  '3、 活动赠送会员权益中包含的免单券不可申请发票、不可提现，此优惠不与其他充值优惠活动同享；',
  '4、 本次活动所充值金额仅限效果图渲染使用，不支持退款和提现；',
  '5、 如有任何问题，请咨询瑞云效果图客服，活动名额数量有限，先到先得！',
  '6、 本次活动最终解释权归瑞云科技所有，瑞云科技有权对恶意刷抢活动资源、利用活动资源从事违法违规行为的用户收回活动参与资格，并对涉事账号进行封禁处理！',
]

class XGTXMAS extends React.PureComponent {
  onClick = () => {
    window.open('https://account.renderbus.com/center/user/topUp', '_blank')
  }

  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props

    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='跨年送会员!最高领12个月会员和500元渲染券 - 瑞云渲染'
          keywords='效果图充值活动,效果图渲染优惠,瑞云渲染充值活动'
          description='Renderbus瑞云渲染效果图用户福利来袭，年终跨年活动，完成实名认证或充值最高可领12个月会员和500元渲染券，赶紧行动抓住年尾这最后一波福利！'
        />
        <XmasContainer>
          <XmasBanner />
          <XmasContent>
            <img className='xmas-title-img t1' src={Title1} alt='充值优惠' />
            <div className='xmas-content c1'>
              {CardItme.map(item => (
                <div className='xmas-gold-card'>
                  <div className='xmas-price'>{item.price}</div>
                  <div className='xmas-discount'>
                    <span>{item.discount}</span>
                    <span>{item.worth}</span>
                  </div>
                  <div className='xmas-detail'>{item.detail}</div>
                  <div className='xmas-button' onClick={this.onClick}>
                    立即充值
                  </div>
                </div>
              ))}
            </div>

            <img className='xmas-title-img t2' src={Title2} alt='参与方式' />
            <div className='xmas-content c2 green-card-container'>
              <picture>
                <source srcSet={GreenCard1} media='(min-width: 600px)' />
                <source srcSet={GreenCard1M} media='(max-width: 600px)' />
                <img src={GreenCard1} alt='福利1' />
              </picture>

              <picture>
                <source srcSet={GreenCard2} media='(min-width: 600px)' />
                <source srcSet={GreenCard2M} media='(max-width: 600px)' />
                <img src={GreenCard2} alt='福利2' />
              </picture>
            </div>

            <img className='xmas-title-img t3' src={Title3} alt='活动规则' />
            <div className='xmas-content c3 activity-rules-container'>
              {ActivityRules.map(item => (
                <div>{item}</div>
              ))}
            </div>
          </XmasContent>
        </XmasContainer>
      </Layout>
    )
  }
}

export default XGTXMAS
